<template>
  <div class="main_container">
    <div class="main_page">
      <div class="page_title">{{ $route.meta.title }}</div>
      <div class="page_containt">
        <div class="page_search">
          <el-form ref="searchForm" :model="searchForm">
            <el-row type="flex">
              <!-- <el-col class="search_col">
                <el-form-item label="" size="small" prop="is_vip">
                  <el-select v-model="searchForm.is_vip" size="small" popper-class="select" style="width: 120px"
                    placeholder="是否会员">
                    <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col> -->
              <el-col class="search_col">
                <el-input size="small" type="text" v-model="searchForm.key" style="width: 150px" placeholder="姓名/手机号" />
              </el-col>
              <el-col class="search_col">
                <el-form-item size="small" prop="reg_begin_at">
                  <el-date-picker size="small" style="width: 160px" v-model="searchForm.reg_begin_at" type="date"
                    value-format="yyyy-MM-dd" placeholder="会员注册开始日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="search_col" style="line-height: 32px"> 至 </el-col>
              <el-col class="search_col">
                <el-form-item size="small" prop="reg_end_at">
                  <el-date-picker size="small" style="width: 160px" v-model="searchForm.reg_end_at" type="date"
                    value-format="yyyy-MM-dd" placeholder="会员注册结束日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <!-- <el-col class="search_col">
                <el-form-item size="small" prop="begin_at">
                  <el-date-picker size="small" style="width: 150px" v-model="searchForm.begin_at" type="date"
                    value-format="yyyy-MM-dd" placeholder="创建开始日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col class="search_col" style="line-height: 32px"> 至 </el-col>
              <el-col class="search_col">
                <el-form-item size="small" prop="end_at">
                  <el-date-picker size="small" style="width: 150px" v-model="searchForm.end_at" type="date"
                    value-format="yyyy-MM-dd" placeholder="创建结束日期">
                  </el-date-picker>
                </el-form-item> 
              </el-col>-->
              <el-col class="search_col">
                <el-button type="primary" size="small" native-type="submit"
                  @click.native.prevent="handleSearch">搜索</el-button>
              </el-col>
              <el-col class="search_col">
                <el-button size="small" @click="resetSearchForm">重置</el-button>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="page_box" ref="pageBox">
          <el-table v-loading="loading" size="small" class="el_table" cell-class-name="el_table_cell"
            header-row-class-name="el_table_header" :data="listData" :height="tableHeight" style="width: 100%">
            <el-table-column label="头像" width="60">
              <template slot-scope="scope">
                <el-image style="width: 40px; height: 40px;border-radius: 50%;" :src="scope.row.avatar_url
                  ? scope.row.avatar_url
                  : '/images/image-empty.png'
                  " fit="contain"></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="姓名">
              <template slot-scope="scope">
                {{ scope.row.name ? scope.row.name : '微信用户' }}
              </template>
            </el-table-column>
            <el-table-column prop="username" label="手机号"> </el-table-column>
            <el-table-column prop="birthday" label="生日"> </el-table-column>
            <el-table-column prop="balance" label="余额" width="100"></el-table-column>
            <el-table-column prop="consume" label="总消费金额" width="100"></el-table-column>
            <el-table-column prop="consume_at" label="最近消费日期" width="140">
            </el-table-column>
            <!-- <el-table-column prop="is_vip" label="是否会员" width="100">
              <template slot-scope="scope">
                {{ scope.row.is_vip == 1 ? '是' : '否' }}
              </template>
            </el-table-column> -->
            <el-table-column prop="reg_at" label="注册会员日期" width="140">
            </el-table-column>
            <!-- <el-table-column prop="created_at" label="创建日期" width="140">
            </el-table-column> -->
          </el-table>
          <div class="page_pagination">
            <el-pagination background layout="total,prev,pager,next,jumper" @current-change="handleCurrentChange"
              :total="pageConfig.counts" :page-size="pageConfig.pageSize" :current-page="pageConfig.pageIndex">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onTableHeight } from "@/lib/table";
import vipApi from "@/api/vip";
export default {
  data() {
    return {
      loading: false,
      typeList:[{
        id:-1,
        name:'全部类型'
      },{
        id:1,
        name:'是会员'
      },{
        id:0,
        name:'非会员'
      }],
      searchForm: {
        is_vip: -1,
        key: "",
        reg_begin_at: "",
        reg_end_at: "",
        begin_at: "",
        end_at: "",
      },
      listData: [],
      pageConfig: { pageSize: 50, pageIndex: 1, counts: 0 },
      tableHeight: 500,
    };
  },
  mounted() {
    onTableHeight(this);
    this.getList();
  },
  methods: {
    //查询
    handleSearch() {
      this.getList(1);
    },
    //重置
    resetSearchForm() {
      if (this.$refs.searchForm) this.$refs.searchForm.resetFields();
      this.getList(1);
    },
    //分页
    handleCurrentChange(val) {
      this.getList(val);
    },
    //列表
    getList(pageIndex = 1) {
      this.loading = true;
      vipApi
        .vipList({
          page_size: this.pageConfig.pageSize,
          page_index: pageIndex,
          ...this.searchForm,
        })
        .then((res) => {
          this.listData = res.list;
          this.pageConfig.pageIndex = res.page.page_index;
          this.pageConfig.counts = res.page.counts;
        })
        .catch((res) => { })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>